<ng-container *transloco="let t">
  <section *ngIf="postData$ | async as posts" class="features">
    <ng-container *ngIf="posts.length; else emptyResultsTemplate">
      <article *ngFor="let post of posts; let i = index">
        <div class="card-header">
          <a
            [routerLink]="post.route"
            *ngIf="post.thumbnailSmall || post.thumbnail"
            class="image card-image"
          >
            <img
              [src]="post.thumbnailSmall || post.thumbnail"
              [attr.alt]="'thumbnail' | transloco"
            />
          </a>
          <h3 class="major">{{ post.title }}</h3>
        </div>
        <div class="card-content">
          <p>
            {{ post.description }}
          </p>
        </div>
        <div class="card-footer">
          <a [routerLink]="post.route" class="special read-on"
            >{{ t('readOn') }}...</a
          >

          <a
            *ngIf="post.publishedAt && post.publishedAtUrl"
            [href]="post.publishedAtUrl"
            class="published-at-link"
            [title]="
              'originallyPublishedAt'
                | transloco: { value: post.publishedAtUrl }
            "
          >
            <img
              *ngIf="post.publishedAtLogo; else showPublisherName"
              class="published-at-logo"
              [src]="post.publishedAtLogo"
              alt="Logo of the published page"
            />
            <ng-template #showPublisherName>{{
              t('originallyPublishedAt', {
                value: post.publishedAtUrl
              })
            }}</ng-template>
          </a>
        </div>
      </article>
    </ng-container>
    <ng-template #emptyResultsTemplate>{{ t('listEmpty') }}</ng-template>
  </section>
  <ul *ngIf="(max && max < cntAll) || keyword || search" class="actions">
    <li>
      <a [routerLink]="['/', content]" class="button">{{
        t('showAll', { value: cntAll })
      }}</a>
    </li>
  </ul>
</ng-container>
