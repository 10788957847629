<!-- Header -->
<ng-container *transloco="let t">
  <nav class="menu-dark" id="navigation" role="navigation" tabindex="0">
    <button
      class="burger-menu"
      role="button"
      (click)="toggleMenu()"
      [attr.aria-label]="'openMenu' | transloco"
      aria-expanded="false"
    >
      <span class="hidden-small">{{ t('menu') }}</span>
    </button>

    <button
      (click)="switchLanguage()"
      [attr.aria-label]="'switchLang' | transloco"
    >
      {{ activeLanguage | uppercase }}
    </button>
  </nav>
  <dk-menu *ngIf="menuOpen" (closeMenu)="toggleMenu()"></dk-menu>
</ng-container>
