<ng-container *transloco="let t">
  <section class="wrapper alt style3">
    <div class="inner">
      <h2 class="major">{{ t('twitterTimeline') }}</h2>
      <div class="twitter-timeline-wrapper">
        <ngx-twitter-timeline
          [data]="twitterTimelineData"
          [opts]="twitterTimelineOpts"
        ></ngx-twitter-timeline>
      </div>
    </div>
  </section>
</ng-container>
