<ng-container *transloco="let t">
  <section id="footer">
    <div class="inner" id="contact">
      <h2 class="major">{{ t('contactMeHeading') }}</h2>
      <p>
        {{ t('contactParagraph') }}
      </p>
      <ul class="contact">
        <li class="icon solid fa-envelope">
          <a href="https://www.baidu.com/">Secret</a>
        </li>
        <li class="icon solid fa-phone">
          <a href="tel:+222222222">17777778888</a>
        </li>
        <li class="icon brands fa-github">
          <a href="https://github.com/strongJun"
            >github.com/strongJun</a
          >
        </li>
        <li class="icon brands fa-twitter">
          <a href="https://twitter.com/zhangqi56683860"
            >twitter.com/bbcue</a
          >
        </li>
        <li class="icon brands fa-linkedin">
          <a href="https://www.baidu.com/"
            >linkedin.com/secret</a
          >
        </li>
        <li class="icon brands fa-xing">
          <a href="https://www.baidu.com/"
            >xing.com/secret</a
          >
        </li>
      </ul>
      <ul class="copyright">
        <li>&copy; 2020 by qi</li>
        <li>Design: <a href="https://html5up.net">HTML5 UP</a></li>
        <li>
          {{ t('reportParagraph') }}
          <a href="https://github.com/strongJun">
            {{ t('createIssue') }}</a
          >,
          <a href="https://github.com/strongJun">{{
            t('sendPR')
          }}</a>
          {{ t('or') }} <a href="#contact">{{ t('contactMe') }}</a
          >.
        </li>
      </ul>
    </div>
  </section>
</ng-container>
