<!-- Banner -->
<ng-container *transloco="let t">
  <section id="banner" routerLink="/">
    <div class="inner">
      <img
        routerLink="/"
        src="assets/images/logo_header.png"
        [attr.alt]="'logo' | transloco"
        id="logo"
      />
      <h1 class="header-title">
        {{ t('hello') }},<br />{{ t('iAm') }} <br />
      </h1>
      <p class="sub-header">{{ t('subHeader') }}</p>
    </div>
  </section>
</ng-container>
