<ng-container *transloco="let t; read: 'publicationsPage'">
  <section id="wrapper">
    <section id="one" class="wrapper spotlight style1">
      <div class="inner">
        <a href="https://angular-buch.com" class="image"><img src="assets/images/friends/boboxiangjun.jpg"
            [attr.alt]="'thumbnail' | transloco" /></a>
        <div class="content">
          <h2 class="major">{{ t('title') }}</h2>
          <p>{{ t('description') }}</p>
          <a href="https://angular-buch.com" class="special">{{
            t('readOn')
          }}</a>
        </div>
      </div>
    </section>
  </section>
</ng-container>
