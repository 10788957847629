<ng-container *transloco="let t; read: 'aboutPage'">
  <section id="about" class="wrapper style1">
    <div class="inner">
      <section class="about">
        <h3 class="major">{{ t('about') }}</h3>
        <p>{{ t('intro') }}</p>
        <p>{{ t('meetup') }}</p>
        <a class="button primary small" rel="noopener noreferrer" href="http://zhangqi.win/">{{ t('meetupCta') }}</a>
      </section>
      <h3 class="major">{{ t('career') }}</h3>
      <section class="features">
        <div class="timeline">
          <div *ngFor="let i of [0, 1, 2, 3, 4, 5, 6].reverse(); let odd = odd" class="container hidden list-item"
            [ngClass]="odd ? 'right' : 'left'" animateOnScroll [animationName]="
              odd ? 'animated slideInRight' : 'animated slideInLeft'
            ">
            <div class="content">
              <h1 class="location">{{ t(i + '.location') }}</h1>
              <h2 class="timeline-heading">
                {{ t(i + '.when') }}
              </h2>
              <h3>
                {{ t(i + '.what') }}
              </h3>
              <h4>
                {{ t(i + '.where') }}
              </h4>
              <p>
                {{ t(i + '.info') }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</ng-container>
